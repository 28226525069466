import { Injectable } from '@angular/core';
import { UrlServices } from '../url.services';
import { GenericService, METHODS } from '../services.util';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';


/**
 * @author atello-barcia
 */
@Injectable()
export class StateAccountService {

    urlServices: UrlServices = new UrlServices();

    constructor(public _genericService: GenericService) {
    }

    intlLatamEcGetAccountCredentials(): Observable<any> {
        const credentials = this.urlServices.getStateAccountUrlCredentials();
        return this._genericService.genericCallServices(METHODS.POST,
            credentials.host, credentials.body);
    }

    /**
     * Get state account by client and catalogue
     */
    intlLatamEcGetStateAccount(body: any, token: string): Observable<any> {
        // let headers = new HttpHeaders();
        // headers = headers.set('Authorization', `Bearer ${token}`);
        // headers = headers.set("Access-Control-Allow-Origin", "*")
        // headers = headers.set("Access-Control-Allow-Methods", "DELETE, POST, GET, OPTIONS")
        // headers = headers.set("Access-Control-Allow-Headers", "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With")
        return this._genericService.genericCallServices(METHODS.POST,
            this.urlServices.getStateAccountUrl(), body, null,null);
    }
}
