import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { TOKE_NAME, TOKE_NAME_OAUTH, CURRENT_FORM_OAUTH } from '../_shared/var.constant';
import { Observable, EMPTY, of } from 'rxjs';
import { tap, catchError, retry } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ResponseContentType } from '@angular/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthorizationService implements HttpInterceptor {


  constructor(private router: Router) {

  }

  @BlockUI() blockUI: NgBlockUI;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    let token = JSON.parse(sessionStorage.getItem(TOKE_NAME));
    let token_oauth = JSON.parse(sessionStorage.getItem(TOKE_NAME_OAUTH));
    if (request.url.indexOf("s3.us-east-1.amazonaws.com") > -1) {
      if (request.url.indexOf(".pdf") > -1) {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/pdf') });
      } else {
        request = request.clone({ headers: request.headers.set('Content-Type', 'image/*') });
      }
    } else {
      if (token && token.access_token && !(request.url.indexOf("/oauth/access-token") > -1) && !(request.url.indexOf("/financial/account") > -1)) {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token.access_token) });
      }
      if (token_oauth && token_oauth.access_token && !(request.url.indexOf("/oauth/access-token") > -1) && !(request.url.indexOf("/financial/account") > -1)) {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token_oauth.access_token) });
      }
      if (token && token.access_token) {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token.access_token) });
      }
      if (request.url.indexOf("/oauth/access-token") > -1) {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/x-www-form-urlencoded') });
      }

      if (token_oauth && token_oauth.access_token) {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token_oauth.access_token) });
      }
      if (request.url.indexOf("/document/files") > -1) {
        request = request.clone({ headers: request.headers.set('Accept', 'text/plain') });
      } else {
        if (!request.headers.has('Content-Type')) {
          request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
      }
    }
    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            if (event.body && event.body.error === true && event.body.errorMessage) {
              throw new Error(event.body.errorMessage);
            }
          }
        })
      , catchError((err: any) => {
        this.blockUI.stop();
        if (err instanceof HttpErrorResponse) {
          if (err.status == 401) {
            if (sessionStorage.getItem(CURRENT_FORM_OAUTH)) {
              let form_id = sessionStorage.getItem(CURRENT_FORM_OAUTH).split('&')
              this.router.navigate(['formulario/', form_id[0], form_id[1]]);
            } else {
              sessionStorage.removeItem(TOKE_NAME)
              this.router.navigate(['/landing'])
            }
          }

          return Observable.throw(err)
        }
        return Observable.throw(err)
      })
    );
  }
}
