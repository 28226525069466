import { HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { TOKE_NAME } from "../_shared/var.constant";


export class UrlServices {
    urlApi = environment.URL_API;
    domain = environment.URL_API_DOCUMENT;
    renovations = this.urlApi + '/renovations'
    incentives = environment.URL_API_INCENTIVE;
    domainApiAccount = environment.URL_API_ACCOUNT_URL;
    domainApiAccountTokenClientSecret = environment.URL_API_ACCOUNT_TOKEN_CLIENT_SECRET;
    domainApiAccountTokenClientId = environment.URL_API_ACCOUNT_TOKEN_CLIENT_ID;
    domainDocuments = this.domain + '/documents';
    domainBestByBranch = this.incentives + '/best-by-branch';
    domainReports = this.domain + '/reports/b2b';
    renovationsList = this.urlApi + '/renovations/list';
    renovationsCount = this.urlApi + '/renovations/countPolicies';
    renovationsDownload = this.urlApi + '/renovations/downloadReport';
    inspectionsPlate = this.domain + '/inspections/consultVehicles'
    inspectionsRAMv = this.domain + '/inspections/consultVehiclesRamv'
    inspectionsSend = this.domain + '/inspections'
    renovationsPDF = this.renovations + '/downloadPdf'
    renovatiosnCertificate = this.renovations + '/downloadCert'
    renovatiosnSendPDF = this.renovations + '/sendPdf'
    files = this.domain + '/files';
    filesProxy = this.domain + 's/s3';
    domainDocumentsBroker = this.domain + '/documents'
    domainCatalogs = this.domain + '/catalogs';
    url_sso_azure = environment.URL_SSO_AZURE;
    url_renovation_app = environment.URL_RENOVATION_APP;

    // context
    formContext: string;

    // forms
    urlFormsBroker: string;
    b2b = '/b2b'

    // Reporte
    getReportForm: string;

    constructor() {
        this.formContext = this.domain + '/documents';
        this.files = this.domain;
        this.urlFormsBroker = this.formContext + '/brokers';
    }

    getUrRenovations(download, count = null) {
        let user = JSON.parse(sessionStorage.getItem('access_token'));
        if (user) {
            return { url: download ? this.renovationsDownload : count ? this.renovationsCount : this.renovationsList, brokerId: user.user.brokerId }
        }
        return {}
    }

    getUrlInspections(isPlate){
        let user = JSON.parse(sessionStorage.getItem('access_token'));
        if (user) {
            return {url: isPlate? this.inspectionsPlate:this.inspectionsPlate}
        }
        return {}
    }
    getUrlInspectionsPost(){
        let user = JSON.parse(sessionStorage.getItem('access_token'));
        if (user) {
            return {url: this.inspectionsSend }
        }
        return {}
    }

    getUrlBestByBranch() {
        let user = JSON.parse(sessionStorage.getItem('access_token'));
        return { url: this.domainBestByBranch, brokerId: user.user.brokerId }
    }

    getEventUrl(dateId, country, event) {
        // 0 b2b - 1 aouth
        let tipo = 1
        let user = JSON.parse(sessionStorage.getItem('access_token'));
        if (user && user.user && user.user.brokerId) {
            tipo = 0
        }
        return this.domainDocuments + `/${encodeURIComponent(String(dateId))}/events/${event}/${tipo == 0 ? 'b2b' : 'basic'}?country=${country}${tipo == 1 ? '&idToken=52fa1bff326ed4d5e05e7f9f23f1ea50' : ''}`
    }

    getUrlAllDocuments(country) {
        let token = JSON.parse(sessionStorage.getItem(TOKE_NAME));
        let brokerId;
        if (token && token.user && token.user.brokerId)
            brokerId = "&brokerId=" + token.user.brokerId;
        return this.domainDocuments + `${this.b2b}?country=${country}${brokerId}`
    }

    getUrlDocuments(dateId, country) {

        // 0 b2b - 1 aouth
        let tipo = 1
        let user = JSON.parse(sessionStorage.getItem('access_token'));
        if (user && user.user && user.user.brokerId) {
            tipo = 0
        }
        return this.domainDocuments + `/${encodeURIComponent(String(dateId))}${tipo == 0 ? this.b2b : ''}?country=${country}`
    }

    getUrlCatalogs(name, countryId, codeParenteId) {

        // 0 b2b - 1 aouth
        let tipo = 1
        let user = JSON.parse(sessionStorage.getItem('access_token'));
        if (user && user.user && user.user.brokerId) {
            tipo = 0
        }
        return `${this.domainCatalogs}/${name}${tipo == 0 ? this.b2b : ''}?country=${countryId}${codeParenteId}`
    }

    getUrlDocumentPut() {

        // 0 b2b - 1 aouth
        let tipo = 1
        let user = JSON.parse(sessionStorage.getItem('access_token'));
        if (user && user.user && user.user.brokerId) {
            tipo = 0
        }
        return this.domainDocuments + `${tipo == 0 ? this.b2b : ''}?`
    }

    getUrlFiles(idForm, country) {

        // 0 b2b - 1 aouth
        let tipo = 1
        let user = JSON.parse(sessionStorage.getItem('access_token'));
        if (user && user.user && user.user.brokerId) {
            tipo = 0
        }
        return this.formContext + `/${idForm}/files${tipo == 0 ? this.b2b : ''}?country=${country}`
    }

    getUrlClient(documentType, documentNumber, isParent) {
        return `${this.domain}/customers/b2b?country=EC&documentType=${documentType}&documentNumber=${documentNumber}&isParent=${isParent}`
    }

    getUrlPolicyPdf(key) {
        return this.domain + `/files/${key}/b2b`;
    }

    /**
     * @author atello-barcia
     * Returns stateAccount context
     */
    getStateAccountUrl(): string {
        return this.domainApiAccount + `/financial/account`;
        // return this.domainApiAccount + `/estadoCuenta`;
    }

    getPDF() {
        return this.renovationsPDF;
    }

    getCertificate() {
        return this.renovatiosnCertificate;
    }

    sendPDFCertificate() {
        return this.renovatiosnSendPDF;
    }

    getURLRenovationsBtnRenovar(item: any) {
        let objectParams = JSON.stringify({
            idPoliza: item.policyNumber,
            idSucursal: item.codBranch,
            idApp: 'R'  //Renovar
        })
        objectParams = btoa(objectParams);
        let urlSSOAzure = this.url_sso_azure.replace("URL_DESTINATION_APP", this.url_renovation_app);
        return `${urlSSOAzure}&state=${encodeURIComponent(objectParams)}`
    }

    getURLRenovationsBtnConsultar(item: any) {
        let objectParams = JSON.stringify({
            idPoliza: item.policyNumber,
            idSucursal: item.codBranch,
            idApp: 'F' //Consultar FV
        })
        objectParams = btoa(objectParams);
        let urlSSOAzure = this.url_sso_azure.replace("URL_DESTINATION_APP", this.url_renovation_app);
        return `${urlSSOAzure}&state=${encodeURIComponent(objectParams)}`
    }

    getStateAccountUrlCredentials() {
        const body = new HttpParams ({
            fromObject: {
                client_id: environment.URL_API_ACCOUNT_TOKEN_CLIENT_ID,
                client_secret: environment.URL_API_ACCOUNT_TOKEN_CLIENT_SECRET,
                grant_type: 'client_credentials'
            }
        });
        return { host: this.domainApiAccount + `/oauth/access-token`, body: body. toString() };
    }
    
}
