// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// protected basePath = 'https://api-dev.liberty.ec/form-testbg';

// Develop
export const environment = {
  production: false,
  URL_API: 'https://api-dev.liberty.ec',
  URL_API_POLICY: 'https://api-dev.liberty.ec/poliza',
  URL_API_FORM: 'https://api-dev.liberty.ec/form',
  URL_API_DOCUMENT: 'https://api-dev.liberty.ec/document',
  URL_API_INCENTIVE: 'https://api-dev.liberty.ec/incentives',
  URL_API_ACCOUNT_URL: 'https://test-apis.us-east-1.libertymutual.com',
  URL_API_ACCOUNT_TOKEN_CLIENT_SECRET: 'V3AylmtvKSB221tn',
  URL_API_ACCOUNT_TOKEN_CLIENT_ID: 'GHgzC4m020h4TYq0NAlBUb97A7KknSJj',
  URL_API_SECURE: 'https://api-dev.liberty.ec/secure-portal',
  URL_GET_TOKEN: 'https://login.microsoftonline.com/LibertyMutual.onmicrosoft.com/oauth2/v2.0/token',
  URL_LOGIN: 'https://login.microsoftonline.com/08a83339-90e7-49bf-9075-957ccd561bf1//oauth2/v2.0/authorize?client_id=a428399d-63c2-4220-9576-716492e3db5f&response_type=code&response_mode=query&resource_id=https://graph.microsoft.com/&redirect_uri=https://intermediarios-dev.liberty.ec&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fuser.read&prompt=select_account',
  URL_LOGOUT: 'https://login.microsoftonline.com/LibertyMutual.onmicrosoft.com/oauth2/v2.0/logout?post_logout_redirect_uri=https://intermediarios-dev.liberty.ec',
  URL_PORTAL: 'https://intermediarios-dev.liberty.ec',
  CLIENT_SECRET: 'NmI1YTI2ZjE5OWJhN2Q1NDcwZGY4ZjFmN2RiMzM4ZjU=',
  URL_API_SECURE_OAUTH: 'https://api-dev.liberty.ec/secure/token',
  URL_NEGOCIOS_AUTOS_NUEVO: 'https://login.microsoftonline.com/LibertyMutual.onmicrosoft.com/oauth2/v2.0/authorize?client_id=a428399d-63c2-4220-9576-716492e3db5f&response_type=code&redirect_uri=https://auto-dev.libertyseguros.ec&scope=user.read+openid+profile+email&response_mode=query&state=12345&nonce=678910',
  URL_NEGOCIOS_AUTOS_OLD: 'https://andino-stagingx.unqork.io/auth/oidc/dev?destination=%23/display/5c910f2e0bbfd1002d97efb2',
  URL_SEARCH_PORTAL: 'https://login.microsoftonline.com/LibertyMutual.onmicrosoft.com/oauth2/v2.0/authorize?client_id=a428399d-63c2-4220-9576-716492e3db5f&response_type=code&redirect_uri=https://auto-dev.libertyseguros.ec&scope=user.read+openid+profile+email&response_mode=query&state=12345&nonce=678910',
  URL_POLLA_COPA_A: 'https://concursos-nonprod.liberty.ec',
  URL_BPM: 'https://bpmdev.hdicolombia.com.co/LBRT_WEB_RadicacionExterna_Ecuador/faces/welcomeClient?ssoIdP=AZURE',
  URL_ACADEMY: 'https://intermediario.academialibertyandes.com/',
  URL_COTIZADOR_REDIRECT: 'https://cotizacion-emision.freshservice.com/support/tickets/new',
  URL_MY_SOLS: 'https://login.microsoftonline.com/LibertyMutual.onmicrosoft.com/oauth2/v2.0/authorize?client_id=a428399d-63c2-4220-9576-716492e3db5f&response_type=code&redirect_uri=https://solicitudes-brokers-dev.libertyseguros.ec&scope=user.read+openid+profile+email&response_mode=query&state=12345&nonce=678910',
  RECORD_TYPE_ID: '0124A000001QhLXQA0',
  CHAT_MARKET: "https://libertysegurosandinomarket--uat.sandbox.my.salesforce.com",
  CHAT_ENCUESTAS: "https://libertysegurosandinomarket--uat.sandbox.my.salesforce-sites.com/prechat",
  CHAT_GLS: "00D7f000000AOj5",
  BASE_LIVE_AGENT_CONTENT_URL: "https://c.la3-c1cs-ia4.salesforceliveagent.com/content",
  CHAT_BUTTON_ID: "5734A000000TY3V",
  BASE_LIVE_AGENT_URL: "https://d.la3-c1cs-ia4.salesforceliveagent.com/chat",
  ESW_LIVE_AGENT_DEV_NAME: "EmbeddedServiceLiveAgent_Parent04I4A000000blKMUAY_1740e704bca",
  CHAT_SRC: "https://libertysegurosandinomarket--uat.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js",
  PIN_RESTORE: "https://validacionpin-dev.libertyseguros.co/restore",
  PIN_HAVENT: "https://validacionpin-dev.libertyseguros.co/",
  URL_SSO_AZURE: "https://login.microsoftonline.com/LibertyMutual.onmicrosoft.com/oauth2/v2.0/authorize?client_id=a428399d-63c2-4220-9576-716492e3db5f&response_type=code&redirect_uri=URL_DESTINATION_APP&scope=user.read+openid+profile+email&response_mode=query&nonce=678910",
  URL_RENOVATION_APP: "https://auto-dev.libertyseguros.ec"
};

