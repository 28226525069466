import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';
import { Properties } from '../../../../../general/properties';
import { DocumentModel, ModelFileModel, FormModel } from 'src/app/_model/documents/models';
import { FormsService } from 'src/app/_services/api_v1/forms.service';
import { FilesService } from 'src/app/_services/api_v1/files.service';
import { DocumentsService } from 'src/app/_services/api_v1/documents.service';

@Component({
    selector: 'app-documentos-PJ-ec',
    templateUrl: './documentosPJ.html',
    styleUrls: ['./documentosPJ.css']

})

export class DocumentosPJComponent implements OnInit {

    properties: Properties = new Properties();
    es: any = this.properties.es;
    titulo = 'Documentos';
    subtitulo = 'Adjúntanos los documentos que te solicitamos a continuación. Se deberá subir solo archivos PDF.';

    titleFormVinculacion = 'Formulario de Vinculación';
    titleRUC1 = 'Copia del Registro Único de Contribuyentes (RUC)';
    titleRUC2 = 'Copia del Registro Único de Contribuyentes (RUC) del Fideicomiso';
    titleEscrituras1 = 'Copia de la escritura de constitución';
    titleEscrituras2 = 'Copia del acuerdo ministerial o instrumento legal que acredite la personaría jurídica y su existencia legal';
    titleEscrituras3 = 'Escritura de Constitución del Fideicomiso';
    titleCertNombRepLegal = 'Copia certificada de nombramiento del Representante Legal o Apoderado';
    titleNomina1 = 'Nómina actualizada de accionistas o socios, en la que consten los montos de acciones ' +
        'o participaciones obtenida por el cliente en el órgano de control o registro competente';
    titleNomina2 = 'Copia de la Nómina del comité administrativo o directiva';
    titleCertificado1 = 'Certificado de cumplimiento de obligaciones otorgado por el órgano de control competente, de ser aplicable*';
    titleCertificado2 = 'Certificado de Cumplimiento de Obligaciones con el SRI';
    titleEstadosFinancieros1 = 'Estados financieros, mínimo de un año atrás. En caso de que por disposición '
        + 'legal, tienen la obligación de contratar a una auditoría externa, los estados financieros deberán ser auditados';
    titleEstadosFinancieros2 = 'Estados financieros, mínimo de un año atrás.';
    titleServicioBasico = 'Copia de un recibo de cualquiera de los servicios básicos';
    titleServicioBasico1 = 'Copia de un recibo de cualquiera de los servicios básicos reverso';
    titelDocIdentidad1 = 'Documentos de identificación de las personas que sean firmas autorizadas de la '
        + 'empresa, o de quienes representen legalmente a la entidad';
    titelDocIdentidad3= ' Copia de la cédula de ciudadanía del Representante Legal o Apoderado'
    titelDocIdentidad2 = 'Documentos de identificación de las personas que representen legalmente a la' +
        ' Administradora de Fondos (En caso si es una persona distinta al Representante Legal entregar el poder que lo '
        + 'faculte a firmar en nombre de la Administradora de Fondos)';
    titleDocIdentidadCoyugue = ' Copia de la cédula de ciudadanía del cónyuge del Representante Legal o Apoderado ';
    titleConfirmPago = 'Confirmación del pago del impuesto a la renta del año inmediato anterior o constancia de la información ' +
        'publicada por el Servicio de Rentas Internas a través de su página web, de ser aplicable*';
    titleConfirmPago1 = 'Confirmación del pago del impuesto a la renta del año inmediato anterior o constancia de la información '
        + 'publicada por el Servicio de Rentas Internas a través de su página web, de ser aplicable (REVERSO)';
    titleImpOblig = '*El documento será obligatorio en caso de que la suma asegurada supere los USD 200.000*'

    // variables
    doc: any;
    step: any;
    estadoFormularioAux: any;

    // Generales
    country: any;
    idForm: any;

    // Modelos
    newDocument: DocumentModel;
    docUpload: ModelFileModel;
    img: any;
    document: DocumentModel;
    companyType: number;
    loading: boolean;
    idDocSection: any;
    sumInsured: any;
    showSpouse: any;
    form: FormModel;


    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        public formService: FormsService,
        private fileService: FilesService,
        private _documentsService: DocumentsService
    ) {
        // this.tipoCompania = this.properties.cmpFundSocSinFD;
    }

    ngOnInit(): void {
        const id = this.route.snapshot.paramMap.get('idFormulario');
        const country = this.route.snapshot.paramMap.get('idCountry');
        this.country = country;
        this.idForm = id;
        this.consultarFormulario();
    }

    consultarFormulario() {
        this.loading = true;
        this.doc = new Object;

        this.doc[this.properties.docRuc] = null;
        this.doc[this.properties.docEscritura] = null;
        this.doc[this.properties.docAcuerdoMinisterial] = null;
        this.doc[this.properties.docCertNombRepLegal] = null;
        this.doc[this.properties.docNomina] = null;
        this.doc[this.properties.docCertificadoCumplimiento] = null;
        this.doc[this.properties.docEstadosFinancieros] = null;
        this.doc[this.properties.docIdentificacionRepresentanteLegal] = null;
        this.doc[this.properties.docIdentificacionConyuge] = null;
        this.doc[this.properties.docPagoImpuetoRenta] = null;
        //this.doc[this.properties.docPagoImpuetoRenta1] = null;
        this._documentsService.getDocumentsByDateIdByCountry(this.idForm, this.country)
            .subscribe((resp: any) => {
                this.document = resp;
                if (this.document.status === 'REVIEWED') {
                    this.router.navigate(['/formulario/finalizado']);
                } else {
                    this.form = new FormModel();
                    this.form = this.document.data;
                    this.sumInsured = this.form.sumInsured;
                    // this.sumInsured = 200000;
                    this.companyType = this.form.company.type.id;
                        this.doc[this.properties.docAcuerdoMinisterial] = '';                       
                       
                        if (this.sumInsured <= 200000) {
                            this.doc[this.properties.docPagoImpuetoRenta] = '';
                        }                     
                    if (this.form.person && this.form.spouse != null) {
                        if (this.form.person.civilStatus !== undefined
                            && this.form.person.civilStatus != null) {
                            if (this.form.person.civilStatus.id === 2 ||
                                this.form.person.civilStatus.id === 3) {

                                this.showSpouse = 1;
                            } else {
                                this.doc[this.properties.docIdentificacionConyuge] = '';
                                this.showSpouse = 0;
                            }
                        }
                    } else {
                        this.doc[this.properties.docIdentificacionConyuge] = '';
                    }
                    this.step = this.form.step;
                    if (this.form.files) {
                        this.form.files.forEach(document => {
                            if (document && typeof document === 'object') {
                                const nameFile = document.type.split('.');
                                this.doc[nameFile[0]] = document;
                                this.doc[nameFile[0]].name = document.type;
                            }
                        });
                    } else {
                        this.form.files = [];
                    }
                }
                this.loading = false;

            },
                (err: any) => {
                    this.loading = true;
                });
    }

    prepareDocuments(files, typeDoc, maxSize = 2) {
        this.doc[typeDoc] = new Object;
        const MAX_IMAGE_SIZE_PROCESS = maxSize * 1048576;
        if (files.target.files && files.target.files.length > 0) {
            this.doc[typeDoc] = files.target.files;
            let type = this.doc[typeDoc][0].type.split('/')[1];
            const size = this.doc[typeDoc][0].size;
            if (type == 'pdf') {
                if (size < MAX_IMAGE_SIZE_PROCESS) {
                    this.doc[typeDoc].name = typeDoc + '.' + type;
                    this.saveFiles(this.doc[typeDoc][0], typeDoc, this.doc[typeDoc].name);
                }
                else {
                    this.doc[typeDoc] = null;
                    this.messageService.add({
                        severity: this.properties.messageTypeError,
                        summary: this.properties.summaryError, detail: `No se pueden ingresar archivos mayores a ${maxSize}MB`
                    });
                }
            } else {
                this.messageService.add({
                    severity: this.properties.messageTypeError,
                    summary: this.properties.summaryError, detail: this.properties.formatNoValidPdf
                });
                this.doc[typeDoc] = null;
            }
        } else {
            this.doc[typeDoc] = null;
        }
    }

    /**
     * Upload documents to S3
     * @param data
     */
     async saveFiles(data, typeDoc, docName) {
        this.loading = true;
        try {
            const documentNumber = this.document.data.person.document.documentNumber;
            const result = await this.fileService.uploadFileWithS3Presigned(docName, documentNumber, 'upload', data);
            const params = {
                type: docName,
                key: `EC/${documentNumber}/FORM/${docName}`,
                location: result.substring(0, result.indexOf('?'))
            };
            const obj = this.document.data.files.find(document => document.type === params.type);
            if (obj !== undefined && this.document.data.files.length > 0) {
                const index = this.document.data.files.indexOf(obj);
                this.document.data.files[index] = params;
            } else {
                this.document.data.files[this.document.data.files.length] = params;
            }
            this.messageService.add({
                severity: this.properties.messageTypeSucccess,
                summary: this.properties.summarySuccess,
                detail: this.properties.docUpdate
            });
        } catch (e) {
            this.doc[typeDoc] = null;
            this.messageService.add({
                severity: this.properties.messageTypeError,
                summary: this.properties.summaryError, detail: this.properties.failedUploadDocument
            });
        }
    }

    actualizarFormulario() {
        this.loading = true;
        this.armarFormulario();

        let valido = true
        for (const key in this.doc) {
            if (this.doc[key] != "" && this.doc[key] == null) {
                valido = false
            }
        }
        if (valido)
            this.newDocument.data.files.map(curr => {
                curr.location = encodeURIComponent(curr.location)
            });
            this._documentsService.updateDocument(this.newDocument).subscribe((resp: any) => {
                this.messageService.add({ severity: 'success', summary: '', detail: '¡Se actualizó correctamente su formulario!' });
                this.loading = false;
                this.router.navigate(['/formulario/finalizado']);
            },
                (err: any) => {
                    this.messageService.add({ severity: 'error', summary: '', detail: '¡No se actualizó correctamente su formulario!' });
                    this.loading = false;
                },
                () => {
                });
    }

    armarFormulario() {
        this.estadoFormularioAux = 'REVIEWED';
        this.document.status = this.estadoFormularioAux;
        this.document.data = this.form;
        this.newDocument = this.document;
        let paso = 4;
        if (paso <= this.step) {
            paso = this.step;
        } else {
            this.step = paso;
        }
        this.newDocument.data.step = this.step;
    }

}
