import { Component, OnInit, Output, EventEmitter, Input, Inject, LOCALE_ID } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from './_handler/login.service';
import { RolesService, AccessService, UsersService, GroupsService, ApiResponse, MenusService } from './_services';
import { JwtHelperService } from '../../node_modules/@auth0/angular-jwt';
import { ReqcodeModel } from './_model/security/reqcode';
import { environment } from '../environments/environment';
import { CODE, TOKE_NAME, TOKEN_ID, USER_MENUS, PATH_PORTAL, Constantes } from './_shared/var.constant';

//Encriptar
import { CriptoService } from '../app/_handler/cripto.service';
import { reject } from 'q';
import * as moment from 'moment';
import { RenovationsService } from './_services/api_v1/renovations.service';
import { NotificationService } from './notification.service';
import { DatePipe } from '@angular/common';

declare var armarChat: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    URL_LOGOUT: string = environment.URL_LOGOUT;
    URL_PORTAL: string = environment.URL_PORTAL;
    // CHAT
    RECORD_TYPE_ID: string = environment.RECORD_TYPE_ID;
    CHAT_MARKET: string = environment.CHAT_MARKET;
    CHAT_ENCUESTAS: string = environment.CHAT_ENCUESTAS;
    CHAT_GLS: string = environment.CHAT_GLS;
    BASE_LIVE_AGENT_CONTENT_URL: string = environment.BASE_LIVE_AGENT_CONTENT_URL;
    CHAT_BUTTON_ID: string = environment.CHAT_BUTTON_ID;
    BASE_LIVE_AGENT_URL: string = environment.BASE_LIVE_AGENT_URL;
    ESW_LIVE_AGENT_DEV_NAME: string = environment.ESW_LIVE_AGENT_DEV_NAME;
    CHAT_SRC: string = environment.CHAT_SRC;
    modelCode: ReqcodeModel;
    code: string;
    helper = new JwtHelperService();
    @Output() cambioNombre = new EventEmitter<boolean>();
    constantes = new Constantes();
    nombreUsuario: string;
    // respMenu: any;
    // menucache = [];
    // menuuser: any;
    // menus = [];
    // submenus = [];
    modelToMenu: any[];
    loadingNotify = false;

    baseUrl: any;
    banderaMenu: number = 0;

    msgs: string;

    menuMode = 'static';

    topbarMenuActive: boolean;

    overlayMenuActive: boolean;

    staticMenuDesktopInactive: boolean;

    staticMenuMobileActive: boolean;

    layoutMenuScroller: HTMLDivElement;

    lightMenu = false;

    menuClick: boolean;

    topbarItemClick: boolean;

    activeTopbarItem: any;

    resetMenu: boolean;

    menuHoverActive: boolean;

    rightPanelActive: boolean;

    rightPanelClick: boolean;

    mobile: any;

    todayDate: string;

    currentUrl: string;

    // Codificadores
    correoCode: any;
    userEmailCode: any;
    respACode: any;
    brokerId: any;
    firstNotifyLoad = null;
    constructor
        (
            private route: ActivatedRoute,
            private router: Router,
            public loginService: LoginService,
            private usersService: UsersService,
            private groupsService: GroupsService,
            private roleService: RolesService,
            private accesService: AccessService,
            private menuService: MenusService,
            private criptoService: CriptoService,
            private renovationsService: RenovationsService,
            private notify: NotificationService,
            private datePipe: DatePipe,
            @Inject(LOCALE_ID) private locale: string,
            private location: Location

        ) {
        const currentDate = new Date();
        this.todayDate = this.datePipe.transform(currentDate, 'd/MMM/yyyy', null, this.locale);
    }

    ngDoCheck() {
        // add renovations validatio
        this.currentUrl = window.location.hash;
    }

    ngOnInit(): void {
        this.code = localStorage.getItem(CODE);
        if (this.code != 'undefined' && this.code != null && this.code != 'null') {
            this.login();
        }
        else {
            let currentUser = JSON.parse(sessionStorage.getItem('access_token'));
            if (currentUser) {
                this.brokerId = currentUser.user.brokerId;
                this.loadChat(this.brokerId);
            }
        }
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        // this.validarMenu();
        setTimeout(() => {
            this.resizeTop();
        }, 300);
    }

    conditionUse(response: ApiResponse) {
        var dataUser = response.data[0].rolesId;
        var redireccionarATerminos = false;
        for (const element in dataUser) {

            if ((dataUser[element] === this.constantes.rolAdminIntermediario
                || dataUser[element] === this.constantes.rolSuperAdminLiberty
                || dataUser[element] === this.constantes.rolAdminIntermediarioPiloto)
                && (response.data[0].termsUseDate == undefined || response.data[0].termsUseDate.length <= 0)) {
                redireccionarATerminos = true;
                break;
            }
        }
        sessionStorage.setItem('sendTermsConditions', JSON.stringify(redireccionarATerminos));
        return redireccionarATerminos;
    }
    login() {
        if (this.loginService.estaLogueado()) {
            return true;
        } else {
            this.modelCode = new ReqcodeModel();
            this.modelCode.code = this.code;
            this.accesService.intlLatamEcSecureGetTokenClient(this.modelCode, TOKEN_ID).subscribe((resp: ApiResponse) => {
                if (resp['data']['error']) {
                    //console.log("Error ", resp);
                } else {
                    let respuesta = resp.data;
                    if (respuesta['access_token']) {
                        let bodyToken = this.helper.decodeToken(respuesta['access_token']);
                        this.nombreUsuario = bodyToken.name;
                        let user = { email: undefined == bodyToken.email ? bodyToken.unique_name : bodyToken.email, name: bodyToken.name, roles: [] };
                        respuesta['user'] = user;
                        sessionStorage.setItem(TOKE_NAME, JSON.stringify(resp.data));
                        // Existe en la aplicacion?
                        this.userEmailCode = null;
                        this.userEmailCode = this.criptoService.set(user.email);
                        this.roleService.intlLatamEcFormSecureGetRolesByUserId(this.userEmailCode).subscribe((resp: ApiResponse) => {
                            if (Number(resp.code) == 200 && resp.data[0].userStatus == 1) {
                                // Verificacion EXIST para obtener el ID del B2B
                                this.usersService.intlLatamEcFormSecureGetUserByEmail(this.userEmailCode).subscribe((respA: any) => {
                                    if (respA.code = "200") {
                                        // this.router.navigate(['home']);
                                        this.respACode = null;
                                        this.respACode = this.criptoService.set(respA.data.value[0].id);
                                        this.groupsService.intlLatamEcFormSecureGetGroupMembersByUserId(this.respACode).subscribe(async (respG: any) => {
                                            if (respG.data != null) {
                                                let tk = JSON.parse(sessionStorage.getItem(TOKE_NAME));
                                                tk.user.roles = resp.data[0].rolesId;
                                                tk.user["groupId"] = respG.data[0].id;
                                                // tk.user["brokerId"] = isNaN(respG.data[0].displayName) == false ? Number(respG.data[0].displayName) : 0;
                                                tk.user["brokerId"] = respG.data[0].displayName;
                                                tk.user["brokerName"] = respG.data[0].description;
                                                sessionStorage.setItem(TOKE_NAME, JSON.stringify(tk));
                                                var responseShowTerms = await this.conditionUse(respG);
                                                let currentUser = JSON.parse(sessionStorage.getItem('access_token'));
                                                this.brokerId = currentUser.user.brokerId;
                                                this.loadChat(this.brokerId);
                                                this.menuService.intlLatamEcFormSecureGetMenusForUser(this.userEmailCode).subscribe((respMenus: ApiResponse) => {
                                                    var respdata = respMenus.data == null ? [] : respMenus.data;
                                                    this.processArray(respdata);
                                                });
                                                if (responseShowTerms) {
                                                    this.router.navigate(['/termCondiciones']);
                                                } else {
                                                    this.router.navigate(['/home']);
                                                };
                                            }
                                        }, (err: any) => console.log(err));
                                    } else {
                                        localStorage.clear();
                                        this.loginService.cerrarSesion();
                                        this.router.navigate(['landing']);//au
                                    }
                                }, (err: any) => console.log(err));
                            } else {
                                // Cuando existe el user en b2b pero no existe en el sistema
                                this.cerrarSesion();
                                this.router.navigate(['landing']);//au
                            }
                        })
                    } else {
                        localStorage.clear();
                        this.loginService.cerrarSesion();
                        this.router.navigate(['landing']);
                    }
                }
            })
        }
    }

    async processArray(array) {
        var menusEditable = array;
        let securityMenus = [];
        menusEditable = menusEditable.filter(data => (data.status === undefined));
        securityMenus = menusEditable.filter(data => (data.routerLink != undefined)).map(data => "/" + data.routerLink)
        // console.log("menusEditable",menusEditable)
        securityMenus.push('/home');
        let result = [];
        result = menusEditable.filter(data => data.parentId == null);

        for (var menu of result) {
            let resultB = [];
            resultB = menusEditable.filter(submenus => submenus.parentId === menu.id)
            if (resultB.length > 0) {
                menu['items'] = resultB;
            }
        }
        this.modelToMenu = result;
        result = result.sort(function (a, b) {
            if (a.sort === b.sort) {
                return a.id.localeCompare(b.id);
            } else {
                return a.sort - b.sort;
            }
        });
        result.forEach(curr => {
            if (curr.items) {
                curr.items = curr.items.sort(function (a, b) { return a.sort - b.sort });
            }
        });
        sessionStorage.setItem(USER_MENUS, JSON.stringify(result));
        await sessionStorage.setItem(PATH_PORTAL, JSON.stringify(securityMenus));
        this.onActivate();
    }

    cerrarSesion() {
        this.loginService.cerrarSesion();
        localStorage.clear();
        window.location.href = this.URL_LOGOUT;
    }

    async onActivate() {
        await this.requestOnChange().catch(val => {
            this.firstNotifyLoad = false;
        }).then(val => {
            this.firstNotifyLoad = false;
        });
        window.scroll(0, 0);
    }

    async getQueryParams() {
        const route = this.route;
        return new Promise(function (resolve, err) {
            route.queryParams.subscribe(async (params) => {
                resolve(params);
            })
        })
    }

    async buildRenovationsNotifications() {
        const self = this;
        const endDate = moment().add(7, 'days').format('YYYYMMDD');
        const params = await self.getQueryParams();
        if (!params['notify']) {
            self.notify.initList([
                {
                    code: 'loading', title: 'Loading', subtitle: `Loading`, value: 'loading'
                }
            ]);
            self.renovationsService.getCountRenovations({ endDate }).subscribe(
                (response) => {
                    self.notify.initList([
                        {
                            code: 'renTotal', title: 'P\u00F3lizas por vencer \u00FAltimos 7 d\u00EDas', subtitle: `${response['total']} P\u00F3lizas`, value: response['total'],
                            onClick: () => { self.router.navigateByUrl('/renovations?notify=true') }
                        }
                    ]);
                }, (error) => {
                    self.notify.initList([]);
                }
            );
        }
    }

    async requestOnChange() {
        const self = this;
        return new Promise(async function (resolve, error) {
            let listAccess = JSON.parse(sessionStorage.getItem(PATH_PORTAL));
            try {
                if (listAccess && listAccess.includes('/renovations')) {
                    self.buildRenovationsNotifications();
                }
                resolve(true);
            } catch (e) {
                error(e);
            }
        });
    }

    resizeTop() {
        if (this.mobile) {
            const elm = <HTMLElement>document.getElementById('layout-main');
            if (elm != null) {
                elm.style.marginTop = '5px';
            }
        }
    }

    onLayoutClick() {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (!this.rightPanelClick) {
            this.rightPanelActive = false;
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.resetMenu = true;
            }

            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }

            this.menuHoverActive = false;
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightPanelClick = false;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.topbarMenuActive = false;

        if (this.isOverlay()) {
            this.overlayMenuActive = !this.overlayMenuActive;
        }
        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;
        }

        event.preventDefault();
    }

    onMenuClick($event) {
        this.menuClick = true;
        this.resetMenu = false;
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }

        event.preventDefault();
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }

    onRightPanelButtonClick(event) {
        this.rightPanelClick = true;
        this.rightPanelActive = !this.rightPanelActive;
        event.preventDefault();
    }

    onRightPanelClick() {
        this.rightPanelClick = true;
    }

    isHorizontal() {
        return this.menuMode === 'horizontal';
    }

    isSlim() {
        return this.menuMode === 'slim';
    }

    isOverlay() {
        return this.menuMode === 'overlay';
    }

    isStatic() {
        return this.menuMode === 'static';
    }

    isMobile() {
        return window.innerWidth < 1025;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    hideOverlayMenu() {
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    }

    loadChat(brokerId) {
        let enviromentsChat = {
            "RECORD_TYPE_ID": this.RECORD_TYPE_ID,
            "CHAT_MARKET": this.CHAT_MARKET,
            "CHAT_ENCUESTAS": this.CHAT_ENCUESTAS,
            "CHAT_GLS": this.CHAT_GLS,
            "BASE_LIVE_AGENT_CONTENT_URL": this.BASE_LIVE_AGENT_CONTENT_URL,
            "CHAT_BUTTON_ID": this.CHAT_BUTTON_ID,
            "BASE_LIVE_AGENT_URL": this.BASE_LIVE_AGENT_URL,
            "ESW_LIVE_AGENT_DEV_NAME": this.ESW_LIVE_AGENT_DEV_NAME,
            "CHAT_SRC": this.CHAT_SRC
        }
        armarChat(brokerId, enviromentsChat);

    }
}