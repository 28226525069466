import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';
import { Properties } from '../../../../../general/properties';
import { DocumentModel, FormModel, ModelFileModel } from '../../../../../../_model/documents/models';
import { FormsService } from '../../../../../../_services/api_v1/forms.service';
import { FilesService } from 'src/app/_services/api_v1/files.service';
import { DocumentsService } from 'src/app/_services/api_v1/documents.service';
import { NgxImageCompressService } from 'ngx-image-compress';


@Component({
    selector: 'app-documentos-ec',
    templateUrl: './documentos.component.html',
    styleUrls: ['./documentos.css'],
})

export class DocumentosComponent implements OnInit {

    properties: Properties = new Properties();
    es: any = this.properties.es;
    titulo = 'Documentos';
    titleRUC1 = 'Copia del Registro Único de Contribuyentes (RUC)';
    subtitulo = 'Adjunta una fotografía/imagen de cada uno de los documentos que te solicitamos a continuación.';
    titleCedula = 'Copia de cédula';
    titleImpOblig = '*El documento será obligatorio en caso de que la suma asegurada supere los USD 100.000*'
    titleCedula1 = 'Copia de cédula reverso';
    titleCedulaConyuge = 'Copia de cédula del cónyuge';
    titleCedulaConyuge1 = 'Copia de cédula del cónyuge reverso';
    titlePapeleta = 'Papeles de votación';
    titlePapeletaConyuge = 'Papeles de votación del cónyuge';
    titleServicioBasico = 'Servicio básico';
    titleImpRenta = 'Impuesto a la renta*';
    titleImpRenta1 = 'Impuesto a la renta reverso';
    mensajeError = '';
    // Generales
    country: any;
    idForm: any;

    // Modelos
    newDocument: DocumentModel;
    document: DocumentModel;
    form: FormModel;
    newForm: FormModel;

    fecha: Date;
    docUpload: ModelFileModel;
    img: any;

    // variables
    docCedula: any;
    docCedula1: any;
    docCedulaCoyuge: any;
    docCedulaCoyuge1: any;
    docPapeleta: any;
    docPapeletaCoyuge: any;
    docServicioBasico: any;
    docImpuestoRenta1: any;
    docImpuestoRenta: any;
    mostrarConyugue: number;
    showRuc: number;
    file: any;
    doc: any;
    docName: any;
    statusFormAux: any;
    step: any;
    loading: boolean;

    // obj report
    objCountriesObligationsTax = {
        'countryObt': null
    };

    filesExtensions = [
        'jpg', 'jpeg', 'pdf', 'docx', 'doc', 'png'
    ];

    objReportPN = {
        'designUrl': 's3:intl-latam-ec-archivos-dev-ec/linkingFormPN.rptdesign',
        'outputBucket': 'intl-latam-ec-archivos-dev-ec',
        'outputKey': 'LinkingFormPN.pdf',
        'parameters': {},
        'references': [],
        'relationshipPep': [],
        'countriesObligationsTax': [],
        'products': [],
        'docBase64': 'false',
        'format': 'pdf',
        'runThenRender': false
    };


    sumInsured: any;

    // Comprimir
    localUrl: any;
    localCompressedURl: any;
    sizeOfOriginalImage: number;
    sizeOFCompressedImage: number;
    imgResultBeforeCompress: string;
    imgResultAfterCompress: string;
    typeDoc: any;
    type: any;


    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        public formService: FormsService,
        private fileService: FilesService,
        private _documentsService: DocumentsService,
        private imageCompress: NgxImageCompressService
    ) { }

    ngOnInit(): void {
        const id = this.route.snapshot.paramMap.get('idFormulario');
        const country = this.route.snapshot.paramMap.get('idCountry');
        this.country = country;
        this.idForm = id;
        this.consultarFormulario();
    }

    consultarFormulario() {
        this.loading = true;
        this.doc = new Object;
        this.doc[this.properties.docCedula] = null;
        this.doc[this.properties.docCedula1] = null;
        this.doc[this.properties.docPapeleta] = null;
        this.doc[this.properties.docImpuestoRenta1] = null;
        this.doc[this.properties.docServicioBasico] = null;
        this.doc[this.properties.docImpuestoRenta] = null;
        this.doc[this.properties.docRuc] = '';
        this._documentsService.getDocumentsByDateIdByCountry(this.idForm, this.country)
            .subscribe((resp: DocumentModel) => {
                this.document = resp;
                this.form = resp.data;
                if (this.document.status === this.properties.estadosDocumentos.PENDING) {
                    this.step = this.form.step;
                    this.sumInsured = this.form.sumInsured;
                    if (this.sumInsured <= 100000) {
                        this.doc[this.properties.docImpuestoRenta] = '';
                    }
                    this.doc[this.properties.docCedulaConyuge] = '';
                    this.doc[this.properties.docCedulaConyuge1] = '';
                    this.mostrarConyugue = 0;
                    if(this.form.work.economicActivity.id == 6) {
                        this.showRuc = 1;
                        this.doc[this.properties.docRuc] = null;
                    }
                    if (this.form.person.civilStatus) {
                        if (this.form.person.civilStatus.id === 2 || this.form.person.civilStatus.id === 3) {
                            this.doc[this.properties.docCedulaConyuge] = null;
                            this.doc[this.properties.docCedulaConyuge1] = null;
                            this.mostrarConyugue = 1;
                        }
                    }
                    if (this.form.files) {
                        this.form.files.forEach(document => {
                            if (document && typeof document === 'object') {
                                const nameFile = document.type.split('.');
                                this.doc[nameFile[0]] = document;
                                this.doc[nameFile[0]].name = document.type;
                            }
                        });
                    } else {
                        this.form.files = [];
                    }
                } else {
                    this.router.navigate(['/formulario/finalizado']);
                }
                this.loading = false;
            },
                (err: any) => {
                    this.loading = true;
                });

    }

    actualizarFormulario() {
        this.loading = true;
        this.armarFormulario();
        this.newDocument.data.files.map(curr => {
            curr.location = encodeURIComponent(curr.location)
        });
        this._documentsService.updateDocument(this.newDocument).subscribe((resp: any) => {
            this.messageService.add({ severity: 'success', summary: '', detail: '¡Se actualizó correctamente su formulario!' });
            this.loading = false;
            this.router.navigate(['/formulario/finalizado']);
        },
            (err: any) => {
                this.messageService.add({ severity: 'error', summary: '', detail: '¡No se actualizó correctamente su formulario!' });
                this.loading = false;
            },
            () => {
            });
    }

    armarFormulario() {
        this.statusFormAux = 'REVIEWED';
        this.document.status = this.statusFormAux;
        this.newDocument = this.document;
        let step = 4;
        if (step <= this.step) {
            step = this.step;
        } else {
            this.step = step;
        }
        this.newDocument.data.step = this.step;
    }

    prepareDocuments(files, typeDoc, maxSize = 2) {
        this.doc[typeDoc] = new Object;
        const MAX_IMAGE_SIZE_PROCESS = maxSize * 1048576;
        if (files.target.files && files.target.files.length > 0) {
            this.doc[typeDoc] = files.target.files;
            let type = this.doc[typeDoc][0].type.split('/')[1];
            const size = this.doc[typeDoc][0].size;
            if (this.filesExtensions.indexOf(type) !== -1) {
                if (size < MAX_IMAGE_SIZE_PROCESS) {
                    this.doc[typeDoc].name = typeDoc + '.' + type;
                    this.saveFiles(this.doc[typeDoc][0], typeDoc, this.doc[typeDoc].name);
                }
                else {
                    this.doc[typeDoc] = null;
                    this.messageService.add({
                        severity: this.properties.messageTypeError,
                        summary: this.properties.summaryError, detail: `No se pueden ingresar archivos mayores a ${maxSize}MB`
                    });
                }
            } else {
                this.messageService.add({
                    severity: this.properties.messageTypeError,
                    summary: this.properties.summaryError, detail: this.properties.formatNoValidImg
                });
                this.doc[typeDoc] = null;
            }
        } else {
            this.doc[typeDoc] = null;
        }
    }

    resizedCanvas(tempImg, typeDoc, type) {
        // Comprobamos con el aspect cómo será la reducción
        // MAX_IMAGE_SIZE_PROCESS es la N que definimos como máxima
        const MAX_IMAGE_SIZE_PROCESS = 1000;
        const MAX_WIDTH = MAX_IMAGE_SIZE_PROCESS;
        const MAX_HEIGHT = MAX_IMAGE_SIZE_PROCESS;
        let tempW = tempImg.width;
        let tempH = tempImg.height;
        if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
                tempH *= MAX_WIDTH / tempW;
                tempW = MAX_WIDTH;
            }
        } else {
            if (tempH > MAX_HEIGHT) {
                tempW *= MAX_HEIGHT / tempH;
                tempH = MAX_HEIGHT;
            }
        }
        // Creamos un canvas para la imagen reducida y la dibujamos
        const resizedCanvas = document.createElement('canvas');
        resizedCanvas.width = tempW;
        resizedCanvas.height = tempH;
        const ctx = resizedCanvas.getContext('2d');
        ctx.drawImage(tempImg, 0, 0, tempW, tempH);
        const dataURL = resizedCanvas.toDataURL('image/jpeg');
        this.img = dataURL;
        this.docUpload = {
            'type': typeDoc + '.' + type,
            'file': (<string>this.img).split(',')[1]
        };
        const data = this.docUpload;
        return data;

    }

    selectFile(event: any, dimensions, typeDoc) {
        var fileName: any;
        this.file = event.target.files[0];
        fileName = this.file['name'];
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.onload = (event: any) => {
                this.localUrl = event.target.result;
                this.compressFile(this.localUrl, fileName, dimensions, typeDoc)
            }
            reader.readAsDataURL(event.target.files[0]);
        }

    }

    compressFile(image, fileName, dimensions, typeDoc) {
        var orientation = -1;
        this.sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
        this.imageCompress.compressFile(image, orientation, dimensions, dimensions).then(
            result => {
                this.imgResultAfterCompress = result;
                this.localCompressedURl = result;
                this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024);
                const imageName = fileName;
                const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress);
                // this.saveFiles(imageBlob, typeDoc);
            }
        );

    }

    dataURItoBlob(dataURI) {
        let img = dataURI;
        this.docUpload = null;
        this.docUpload = {
            'type': this.typeDoc + '.' + this.type,
            'file': (<string>img).split(',')[1]
        };
        const blob = this.docUpload;
        return blob;
    }
    /**
     * Upload documents to S3
     * @param data
     */
    async saveFiles(data, typeDoc, docName) {
        this.loading = true;
        try {
            const documentNumber = this.document.data.person.document.documentNumber;
            const result = await this.fileService.uploadFileWithS3Presigned(docName, documentNumber, 'upload', data);
            const params = {
                type: docName,
                key: `EC/${documentNumber}/FORM/${docName}`,
                location: result.substring(0, result.indexOf('?'))
            };
            const obj = this.document.data.files.find(document => document.type === params.type);
            if (obj !== undefined && this.document.data.files.length > 0) {
                const index = this.document.data.files.indexOf(obj);
                this.document.data.files[index] = params;
            } else {
                this.document.data.files[this.document.data.files.length] = params;
            }
            this.messageService.add({
                severity: this.properties.messageTypeSucccess,
                summary: this.properties.summarySuccess,
                detail: this.properties.docUpdate
            });
        } catch (e) {
            this.doc[typeDoc] = null;
            this.messageService.add({
                severity: this.properties.messageTypeError,
                summary: this.properties.summaryError, detail: this.properties.failedUploadDocument
            });
        }
        //     this.fileService.intlLatamEcMilfAddFilesS3(data, this.idForm, this.country).subscribe((dataDoc: any) => {
        //         if (this.document.data.files && dataDoc.key && dataDoc.location) {
        //            
        //         }  else if (!this.document.data.files) {
        //             this.doc[typeDoc] = null;
        //             this.messageService.add({
        //                 severity: this.properties.messageTypeError,
        //                 summary: this.properties.summaryError, detail: this.properties.noExistForm
        //             });
        //         } else {
        //             this.doc[typeDoc] = null;
        //             this.messageService.add({
        //                 severity: this.properties.messageTypeError,
        //                 summary: this.properties.summaryError, detail: this.properties.failedUploadDocument
        //             });
        //         }
        //         this.loading = false;
        //     },
        //         (errorServices) => {
        //             this.mensajeError = errorServices.errorMessage;
        //             this.loading = false;
        //             this.doc[typeDoc] = null;
        //         });
    }
}
